<template lang="pug">
AppLayout
  template(v-slot:header)
    v-spacer
    v-toolbar-title.cash-register-name.mx-2.align-center reKassa
    v-spacer
    v-icon mdi-calendar

  //- .pa-2.d-flex.align-center.justify-center.flex-column

  .d-flex.align-center.justify-center.fill-height.mt-n5
    v-img(src="/logo/Rekassa_Hoodie2.png", width="360", contain)
</template>
<script>
import AppLayout from 'T@/AppLayout.vue'

export default {
  components: {
    AppLayout,
  },
}
</script>
<style lang="stylus"></style>
